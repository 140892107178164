document.addEventListener('DOMContentLoaded', () => {
    const videoBanners = document.querySelectorAll('.bv__item[data-banner-video-link]');

    videoBanners.forEach((item) => {
        item.addEventListener('click', () => {
            const link = item.getAttribute('data-banner-video-link');
            window.location.href = link;
        });
    });
});
